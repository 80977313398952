import React from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import { H1, Paragraph } from 'src/DesignSystem';
import LittleDevilsLogo from 'src/images/little-devils-logo.png';
import NeonSunLogo from 'src/images/neon-sun-logo.png';
import Tag from 'src/components/Tag';
import {
  faClock,
  faUser,
  faFile,
  faCodeBranch,
  faLongArrowAltRight,
} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SEO from 'src/components/SEO';

export default function Games(): JSX.Element {
  return (
    <BasicLayout>
      <SEO title="Games" description="RPGs created by Sam Beckmann" />
      <div className="max-w-4xl p-4 mx-auto text-gray-900 mb-8">
        <H1>Games</H1>
        <Paragraph>A variety of RPGs I&apos;ve designed.</Paragraph>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
          <div className="rounded border-2 p-4">
            <img className="mx-auto mb-4" src={LittleDevilsLogo} />
            <Tag color="orange" icon={faClock}>
              1 session
            </Tag>
            <Tag color="purple" icon={faUser}>
              3-5 + GM
            </Tag>
            <Tag color="blue" icon={faFile}>
              1 page RPG
            </Tag>
            <Tag color="teal" icon={faCodeBranch}>
              v1.2
            </Tag>
            <Paragraph className="mt-2">
              You are the naughty children of an estranged billionaire, living
              in a fabulous mansion and bored out of your minds. So bored, in
              fact, that you made a deal with a Devil in order to gain the power
              to torment your nanny and sow chaos. And all you had to give up in
              return was your humanity!
            </Paragraph>
            <div className="flex justify-around mt-2">
              <Link
                to="/games/little-devils"
                className={clsx(
                  'bg-transparent',
                  'hover:bg-pink-500',
                  'text-pink-700',
                  'font-medium',
                  'hover:text-white border',
                  'border-pink-500',
                  'hover:border-transparent',
                  'py-2',
                  'px-4',
                  'rounded',
                  'focus:outline-none'
                )}
              >
                Go to Game
                <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-2" />
              </Link>
            </div>
          </div>
          <div className="rounded border-2 p-4">
            <img className="mx-auto mb-4" src={NeonSunLogo} />
            <Tag color="orange" icon={faClock}>
              1-2 sessions
            </Tag>
            <Tag color="purple" icon={faUser}>
              5 + GM
            </Tag>
            <Tag color="teal" icon={faCodeBranch}>
              Mk. 5
            </Tag>
            <Paragraph className="mt-2">
              Neon Sun has created <em>The Fountain of Youth</em>. Rumors
              disagree about precisely what this invention is, what it does, and
              how it works, but what’s clear is that it’s well protected, and
              everyone wants it. So Yi Shin is going to steal it. He’s hired a
              crew of unlikely allies to pull off a heist that the world
              believes is impossible.
            </Paragraph>
            <div className="flex justify-around mt-2">
              <Link
                to="/games/neon-sun"
                className={clsx(
                  'bg-transparent',
                  'hover:bg-pink-500',
                  'text-pink-700',
                  'font-medium',
                  'hover:text-white border',
                  'border-pink-500',
                  'hover:border-transparent',
                  'py-2',
                  'px-4',
                  'rounded',
                  'focus:outline-none'
                )}
              >
                Go to Game
                <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
